import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import { captureException } from '@sentry/react';

import Alert from 'interfaces/alert.interface';

import { store } from 'store';

import { setSnackAlert } from 'store/actions/snack-alert';
import { removeUser } from 'store/actions/user';

import { logOnDev } from './logOnDev';

const notify = (
  message: Alert['message'],
  severity: Alert['severity'] = 'error',
) => {
  store.dispatch(
    setSnackAlert({
      open: true,
      message,
      severity,
    }),
  );
};

const onRequest = (config: AxiosRequestConfig) => {
  const method = config?.method;
  const url = config?.url;

  logOnDev(`[request] ${method?.toUpperCase()} ${url}`);

  return config;
};

const onRequestError = (error: AxiosError<any>) => {
  const method = error.config?.method;
  const url = error.config?.url;

  logOnDev(`[request error] ${method?.toUpperCase()} ${url}`);

  return Promise.reject(error); // NOSONAR
};

const onResponse = (response: AxiosResponse) => {
  const method = response.config?.method;
  const url = response.config?.url;

  const status = response.status;

  logOnDev(`[response] ${method?.toUpperCase()} ${url} ${status}`);

  return response;
};

const onResponseError = (error: AxiosError<any>) => {
  const method = error.config?.method;
  const url = error.config?.url;

  const suppressErrorAlert = error.config?.suppressErrorAlert;

  const status = error?.response?.status;
  const dataCode = error?.response?.data?.code;
  const dataMessage = error?.response?.data?.message;

  logOnDev(`[response error] ${method?.toUpperCase()} ${url} ${status}`);

  if (suppressErrorAlert) {
    return Promise.reject(error); // NOSONAR
  }

  /**
   * Support Tool
   *
   * Interceptor to close the support session window in case of an error
   */
  if (dataCode) {
    if (
      dataCode === 'S0001' ||
      dataCode === 'S0006' ||
      dataCode === 'S0007' ||
      dataCode === 'S9998'
    ) {
      if (window.opener && window.name !== '') {
        setTimeout(() => {
          window.close();
        }, 2000);
      }
    }
  }

  if (typeof status === 'number' && status >= 400 && status < 500) {
    if (status === 400) {
      if (dataCode === '87' || dataCode === '81') {
        return Promise.reject(error); // NOSONAR
      }
    }

    /**
     * A 401 can occur for a number of reasons,
     * which is why it's important to check the code returned to us.
     */
    if (status === 401) {
      if (dataCode === 'S0003') {
        store.dispatch(removeUser());
      }
    } else {
      notify(dataMessage ?? error.message);
    }

    return Promise.reject(error); // NOSONAR
  }

  if (typeof status === 'number' && status >= 500) {
    captureException(error, {
      contexts: {
        response: {
          headers: error?.response?.headers,
          status_code: error?.response?.status,
        },
      },
    });

    const message =
      'Oops! A server error has occurred. Our team is working to resolve it quickly. Thank you for your understanding.';

    notify(message);

    return Promise.reject(error); // NOSONAR
  }

  captureException(error, {
    contexts: {
      response: {
        headers: error?.response?.headers,
        status_code: error?.response?.status,
      },
    },
  });

  const message =
    'Oops! An unknown error has occurred. Our team is working to resolve it quickly. Thank you for your understanding.';

  notify(message);

  return Promise.reject(error); // NOSONAR
};

export function setupInterceptors(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
}
