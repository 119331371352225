import { lazy, ReactNode, Suspense } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import SplashScreen from '../components/SplashScreen/SplashScreen';

import PrivateLayout from '../layout/PrivateLayout/PrivateLayout';

import routes, { organizationRoutes } from './routes';

const TasksView = lazy(() => import('../views/ControllerTasks/Tasks'));

const ViewEmployee = lazy(
  () => import('../views/DealerInfo/ViewDealer/ViewEmployee/ViewEmployee'),
);
const ProfileDealerInfoView = lazy(
  () => import('../views/DealerInfo/ViewDealer/ViewDealer'),
);

const ViewDealerRequest = lazy(
  () => import('../views/DealerRequest/ViewDealerRequest/ViewDealerRequest'),
);
const DealerRequests = lazy(
  () => import('../views/DealerRequest/DealerRequest'),
);

const CreateDealersView = lazy(
  () => import('../views/Dealers/AddDealerModal/AddDealerModal'),
);
const DealersInfoView = lazy(
  () => import('../views/Dealers/ViewDealer/ViewDealer'),
);
const DealersView = lazy(() => import('../views/Dealers/Dealers'));

const FirmwareView = lazy(() => import('../views/Firmware/Firmware'));

const HomeView = lazy(() => import('../views/Home/Home'));

const MenusView = lazy(() => import('../views/MenuList/MenuList'));

const OrganizationView = lazy(
  () => import('../views/Organization/Organization'),
);
const UsersCreate = lazy(
  () => import('../views/Organization/users/create/UsersCreate'),
);

const OrganizationsTabsView = lazy(
  () => import('../views/Organizations/CreateTabs/CreateTabs'),
);
const OrganizationsView = lazy(
  () => import('../views/Organizations/Organizations'),
);

const PermissionsView = lazy(
  () => import('../views/PermissionsList/PermissionsList'),
);

const PricingView = lazy(() => import('../views/Pricing/Pricing'));

const ProfileView = lazy(() => import('../views/Profile/Profile'));

const CreateResourceView = lazy(
  () => import('../views/Resources/CreateResource/CreateResource'),
);
const EditResourceView = lazy(
  () => import('../views/Resources/EditResource/EditResource'),
);
const PreviewResourceView = lazy(
  () => import('../views/Resources/PreviewResource/PreviewResource'),
);
const ResourcesView = lazy(() => import('../views/Resources/Resources'));

const RolesByView = lazy(() => import('../views/RolesList/RolesBy/RolesBy'));
const UsersByRole = lazy(
  () => import('../views/RolesList/UsersByRole/UsersByRole'),
);
const RolesListView = lazy(() => import('../views/RolesList/RolesList'));

const SupportCurrentSessionView = lazy(
  () => import('../views/SupportTool/CurrentSession/CurrentSession'),
);
const SupportDiagnoseControllerView = lazy(
  () => import('../views/SupportTool/DiagnoseController/DiagnoseController'),
);
const SupportNewSessionView = lazy(
  () => import('../views/SupportTool/NewSession/NewSession'),
);
const SupportOpenSessionsView = lazy(
  () => import('../views/SupportTool/OpenSession/OpenSessions'),
);

const TestingtoolView = lazy(() => import('../views/TestingTool/TestingTool'));

const UsersView = lazy(() => import('../views/Users/Users'));

const HelpConfiguration = lazy(
  () => import('../views/HelpConfiguration/HelpConfiguration'),
);

const renderPaths = (paths: string[], component: ReactNode) => {
  return paths.map((path) => (
    <Route
      key={path}
      path={path}
      element={<Suspense fallback={<SplashScreen />}>{component}</Suspense>}
    />
  ));
};

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<PrivateLayout />}>
        <Route
          path={routes.role_list}
          element={
            <Suspense fallback={<SplashScreen />}>
              <RolesListView />
            </Suspense>
          }
        />
        <Route
          path={routes.role_view}
          element={
            <Suspense fallback={<SplashScreen />}>
              <UsersByRole />
            </Suspense>
          }
        />
        <Route
          path={routes.user_list}
          element={
            <Suspense fallback={<SplashScreen />}>
              <UsersView />
            </Suspense>
          }
        />
        <Route
          path={routes.dealer_list}
          element={
            <Suspense fallback={<SplashScreen />}>
              <DealersView />
            </Suspense>
          }
        />
        <Route
          path={routes.dealer_create}
          element={
            <Suspense fallback={<SplashScreen />}>
              <CreateDealersView />
            </Suspense>
          }
        />

        <Route
          path={routes.dealer_view}
          element={
            <Suspense fallback={<SplashScreen />}>
              <DealersInfoView />
            </Suspense>
          }
        />
        <Route
          path={routes.dealer_request_list}
          element={
            <Suspense fallback={<SplashScreen />}>
              <DealerRequests />
            </Suspense>
          }
        />
        <Route
          path={routes.dealer_request_view}
          element={
            <Suspense fallback={<SplashScreen />}>
              <ViewDealerRequest />
            </Suspense>
          }
        />
        <Route
          path={routes.permission_list}
          element={
            <Suspense fallback={<SplashScreen />}>
              <PermissionsView />
            </Suspense>
          }
        />
        <Route
          path={routes.role_permission}
          element={
            <Suspense fallback={<SplashScreen />}>
              <RolesByView />
            </Suspense>
          }
        />
        <Route
          path={routes.menu_list}
          element={
            <Suspense fallback={<SplashScreen />}>
              <MenusView />
            </Suspense>
          }
        />
        <Route
          path={routes.role_menu}
          element={
            <Suspense fallback={<SplashScreen />}>
              <RolesByView />
            </Suspense>
          }
        />
        <Route
          path={routes.tasks_list}
          element={
            <Suspense fallback={<SplashScreen />}>
              <TasksView />
            </Suspense>
          }
        />

        <Route
          path={routes.organization_list}
          element={
            <Suspense fallback={<SplashScreen />}>
              <OrganizationsView />
            </Suspense>
          }
        />
        <Route
          path={routes.organization_create}
          element={
            <Suspense fallback={<SplashScreen />}>
              <OrganizationsTabsView />
            </Suspense>
          }
        />
        <Route
          path={routes.testing_tool}
          element={
            <Suspense fallback={<SplashScreen />}>
              <TestingtoolView />
            </Suspense>
          }
        />
        <Route
          path={routes.profile}
          element={
            <Suspense fallback={<SplashScreen />}>
              <ProfileView />
            </Suspense>
          }
        />
        <Route
          path={routes.dealer_info_view}
          element={
            <Suspense fallback={<SplashScreen />}>
              <ProfileDealerInfoView />
            </Suspense>
          }
        />
        <Route
          path={routes.employee_org_view}
          element={
            <Suspense fallback={<SplashScreen />}>
              <ViewEmployee />
            </Suspense>
          }
        />
        <Route
          path={routes.resources_list}
          element={
            <Suspense fallback={<SplashScreen />}>
              <ResourcesView />
            </Suspense>
          }
        />
        <Route
          path={routes.resources_create}
          element={
            <Suspense fallback={<SplashScreen />}>
              <CreateResourceView />
            </Suspense>
          }
        />
        <Route
          path={routes.resources_view}
          element={
            <Suspense fallback={<SplashScreen />}>
              <PreviewResourceView />
            </Suspense>
          }
        />
        <Route
          path={routes.resources_edit}
          element={
            <Suspense fallback={<SplashScreen />}>
              <EditResourceView />
            </Suspense>
          }
        />
        <Route
          path={routes.home}
          element={
            <Suspense fallback={<SplashScreen />}>
              <HomeView />
            </Suspense>
          }
        />
        <Route
          path={routes.pricing}
          element={
            <Suspense fallback={<SplashScreen />}>
              <PricingView />
            </Suspense>
          }
        />
        <Route
          path={routes.firmware_list}
          element={
            <Suspense fallback={<SplashScreen />}>
              <FirmwareView />
            </Suspense>
          }
        />
        <Route
          path={routes.support_new_session}
          element={
            <Suspense fallback={<SplashScreen />}>
              <SupportNewSessionView />
            </Suspense>
          }
        />
        <Route
          path={routes.support_open_sessions}
          element={
            <Suspense fallback={<SplashScreen />}>
              <SupportOpenSessionsView />
            </Suspense>
          }
        />
        <Route
          path={routes.support_current_session}
          element={
            <Suspense fallback={<SplashScreen />}>
              <SupportCurrentSessionView />
            </Suspense>
          }
        />
        <Route
          path={routes.support_diagnose_controller}
          element={
            <Suspense fallback={<SplashScreen />}>
              <SupportDiagnoseControllerView />
            </Suspense>
          }
        />

        <Route
          path={routes.help_configuration}
          element={
            <Suspense fallback={<SplashScreen />}>
              <HelpConfiguration />
            </Suspense>
          }
        />

        {renderPaths(Object.values(organizationRoutes), <OrganizationView />)}

        <Route
          path="/users/create"
          element={
            <Suspense fallback={<SplashScreen />}>
              <UsersCreate />
            </Suspense>
          }
        />

        {/* Page Not Found */}
        <Route
          path="*"
          element={<Navigate to="/home" />}
        />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };
